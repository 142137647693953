<template>
<b-card v-if="users" no-body class="card-company-table">
    <b-table :items="users" responsive :fields="fields" class="mb-0">
        <!-- Id -->
        <template #cell(id)="data">
            <span class="font-weight-bolder mb-12">#{{ data.index + 1 }}</span>
        </template>

        <!-- CreatedAt Time -->
        <template #cell(createdAt)="data">
            <span class="font-weight-bolder mb-12">{{
          data.item.createdAt | moment("DD.MM.YYYY hh:mm")
        }}</span>
        </template>

        <!-- Status -->
        <template #cell(isActive)="data">
            <b-badge pill :variant="data.item.isActive ? 'light-success' : 'light-danger'">
                {{ data.item.isActive ? "AKTİF" : "PASİF" }}
            </b-badge>
        </template>

        <!-- Actions -->
        <template #cell(actions)="data">
            <b-button variant="outline-primary" class="btn-icon rounded-circle" :to="{
            name: 'teacher-child-lessons-page',
            params: {
              lessonId: data.item.lesson.id,
              classId: data.item.class.id,
            },
          }" :id="`package-row-${data.item.id}-status-icon`">
                <feather-icon icon="ArchiveIcon" />
            </b-button>
            <!-- <b-tooltip title="Ders İçeriği" :target="`package-row-${data.item.id}-status-icon`" /> -->
        </template>
    </b-table>
    <b-modal v-model="isSecurityShow" hide-header-close no-close-on-backdrop no-close-on-esc id="modal-login" cancel-variant="outline-secondary" ok-title="Listele" cancel-title="İptal" centered close-disabled @ok="checkSecure" title="Özel Alan">
        <b-form>
            <b-form-group>
                <label for="password">Şifre</label>
                <b-form-input type="password" v-model="password" placeholder="Lütfen Özel Şifreyi Giriniz!" />
            </b-form-group>
        </b-form>
    </b-modal>
</b-card>
</template>

<script>
import {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BButton,
    BTooltip,
    BFormGroup,
    BFormInput,
} from "bootstrap-vue";

export default {
    components: {
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BButton,
        BTooltip,
        BFormGroup,
        BFormInput,
    },
    data() {
        return {
            fields: [
                { key: "id", label: "Ref" },
                { key: "createdAt", label: "Kayıt Tarihi" },
                { key: "isActive", label: "Durum" },
                { key: "class.name", label: "Sınıf Adı" },
                { key: "lesson.code", label: "Ders Kodu" },
                { key: "lesson.name", label: "Ders Adı" },
                { key: "actions", label: "İşlemler" },
            ],
            users: [],
            isSecurityShow: false,
            password: "",
        };
    },
    created() {
        this.getData();
    },
    methods: {
        checkSecure(e) {
            e.preventDefault()
            if (this.password == "190386") {
                this.isSecurityShow = false;
                this.getData();
            } else {
                this.isSecurityShow = true;
            }
        },
        async getData() {
            var users = await this.$http.get("Teacher/Lessons");
            this.users = users.data.data;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
